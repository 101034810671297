<template>
<v-app light>
    <!-- <loading v-if="loading" /> -->
    <v-container fluid>
        <v-card flat>
            <v-container fluid>
                <v-row align="center" justify="center" class="mx-4">
                    <v-col>
                        <img :src="$config.logo" width="150px" />
                    </v-col>
                   
                    <v-col class="text-end">
                        <h3>
                            <strong>
                                تقرير الشحنات
                            </strong>
                        </h3>
                        <span class="grey--text">{{new Date().toISOString().substr(0,10)}} - {{ formatDate(new Date().toISOString()) }}</span>
                    </v-col>
                    <v-col cols="12">
                        <v-divider />
                    </v-col>
                    <v-col cols="12">
                        <v-card outlined>
                            <v-container fluid>
                                <table style="width:60%">
                                    <tr>
                                        <td>
                                            <h4>
                                                <strong>
                                                    مجموع قيم الشحنات:
                                                </strong>
                                            </h4>
                                        </td>
                                        <td>
                                            <strong style="font-size: 16px">
                                                {{ $service.formatCurrency(currentItem.reduce((sum, item) => sum + item.packageValue, 0)) }} IQD
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr class="text--center">
                                        <td colspan="2">
                                            <v-divider class="my-2 mx-auto " />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <h4>
                                                <strong>
                                                    مجموع المبالغ المستلمة:
                                                </strong>
                                            </h4>
                                        </td>
                                        <td>
                                            <strong style="font-size: 16px">
                                                {{ $service.formatCurrency(currentItem.reduce((sum, item) => sum + item.recivedAmount, 0)) }} IQD
                                            </strong>
                                        </td>
                                    </tr>
                                    <tr class="text--center">
                                        <td colspan="2">
                                            <v-divider class="my-2 mx-auto " />
                                        </td>
                                    </tr>
                                   
                                    <tr>
                                        <td>
                                            <h4>
                                                <strong>
                                                    عدد العناصر:
                                                </strong>
                                            </h4>
                                        </td>
                                        <td>
                                            <strong style="font-size: 16px">
                                                {{ currentItem.length }}
                                            </strong>
                                        </td>
                                    </tr>
                                </table>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-divider style="border: dashed 1px #A3A3A3" />
                    </v-col>
                    <v-col cols="12">
                        <table id="orders" style="width: 100%">
                            <thead>
                                <tr>
                                    <th>
                                        ت
                                    </th>
                                    <th>
                                        رقم الشحنة
                                    </th>
                                    <th>
                                        التاريخ
                                    </th>
                                    <th>
                                        التاجر
                                    </th>
                                    <th>
                                        الزبون
                                    </th>
                                    <th>
                                        قيمة الشحنة
                                    </th>
                                    <th>
                                        المبلغ المستلم
                                    </th>
                                    <th>
                                        حالة الطلب
                                    </th>
                                   
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item,index) in currentItem" :key="index">
                                    <td>{{index + 1}}</td>
                                    <td>{{item.no}}</td>
                                    <td>{{item.created.substr(0,10) }}</td>
                                    <td>{{ item.merchant.fullName }}</td>
                                    <td>{{ item.customerName }}</td>
                                    <td>{{ $service.formatCurrency(item.packageValue) }}</td>
                                    <td>{{ $service.formatCurrency(item.recivedAmount) }}</td>
                                    <td>
                                        <div :style="'color:'+item.status.color">
                                            {{ item.status.arabicName}}
                                        </div>
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </v-col>
                    
                    <v-col cols="12">
                        <v-divider style="border: dashed 1px #A3A3A3" />
                    </v-col>

                    <v-col cols="6">
                        <v-card outlined style="border: dashed 1px #A3A3A3">
                            <v-container>
                                <h4>التوقيع</h4>
                            </v-container>
                        </v-card>
                    </v-col>
                    <v-col cols="6">
                        <v-card outlined style="border: dashed 1px #A3A3A3">
                            <v-container>
                                <h4>ملاحظات</h4>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-card>
    </v-container>
</v-app>
</template>

<style type="text/scss">

#orders {
    border-collapse: collapse;
    width: 100%;
    font-family: "Arial";
}

#orders td,
#orders th {
    border: 1px solid #ddd;
    padding: 8px;
    font-size: 16px;
}

#orders tr:nth-child(even) {
    background-color: #f2f2f2;
}

#orders tr:hover {
    background-color: #ddd;
}

#orders th {
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #202020;
    color: white;
}

</style>

    
<script>
import moment from 'moment';
export default {
    props: ["currentItem"],
    data() {
        return {
            loading: false,
            // logo: "",
        }
    },

    methods: {
        formatDate(date) {
            return moment(date).format('h:mm:ss a');
        },

        getPaymentType(paymentType, transactionType) {
            const PaymentTransactionStatusEnum = {
                NotPaid: "NotPaid",
                PaidPartial: "PaidPartial",
                FullPaid: "FullPaid"
            };

            const PaymentTransactionTypeEnum = {
                Credit: "Credit",
                Debit: "Debit" 
            };

            if (transactionType == PaymentTransactionTypeEnum.Credit) {
                switch (paymentType) {
                    case 0:
                        return "غير مدفوع";
                    case 1:
                        return "مدفوع جزئي";
                    case 2:
                        return "مدفوع كامل";
                    default:
                        return "غير معروف";
                }
            } else {
                switch (paymentType) {
                    case 0:
                        return "غير مستلم";
                    case 1:
                        return "مستلم جزئي";
                    case 2:
                        return "مستلم كامل";
                    default:
                        return "غير معروف";
                }
            }
        }
    },
}
</script>
